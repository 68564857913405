import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        color: 'white',
        width: '100%',
        height: { xs: '600px', sm: '400px' },
        display: 'flex',
        alignItems: 'center',
        marginTop: 'auto', // This will push the footer to the bottom
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          py: { xs: 3, sm: 4 },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid 
          container 
          spacing={3}
          sx={{ 
            flexGrow: 1,
            mb: 2
          }}
        >
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontWeight: 600,
              }}
            >
              Asphalt Calculator Pro
            </Typography>
            <Typography 
              variant="body2"
              sx={{
                maxWidth: '90%'
              }}
            >
              Your go-to asphalt calculator and estimator for precise paving project calculations. Trusted by contractors and DIY enthusiasts for professional-grade results.
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontWeight: 600,
              }}
            >
              Resources
            </Typography>
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Link href="/calculator" color="inherit" sx={{ textDecoration: 'none' }}>
                Asphalt Calculator
              </Link>
              <Link href="/density-guide" color="inherit" sx={{ textDecoration: 'none' }}>
                Asphalt Density Guide
              </Link>
              <Link href="/measurement-guide" color="inherit" sx={{ textDecoration: 'none' }}>
                Asphalt Measurement Guide
              </Link>
              <Link href="/application-guide" color="inherit" sx={{ textDecoration: 'none' }}>
                Asphalt Application Guide
              </Link>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontWeight: 600,
              }}
            >
              Legal
            </Typography>
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              <Link href="/privacy-policy" color="inherit" sx={{ textDecoration: 'none' }}>
                Privacy Policy
              </Link>
              <Link href="/terms-of-service" color="inherit" sx={{ textDecoration: 'none' }}>
                Terms of Service
              </Link>
              <Link component={RouterLink} to="/disclaimer" color="inherit" sx={{ textDecoration: 'none' }}>
                Disclaimer
              </Link>
              <Link component={RouterLink} to="/contact" color="inherit" sx={{ textDecoration: 'none' }}>
                Contact
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Typography 
          variant="body2" 
          sx={{ 
            textAlign: 'center',
            py: 2,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          }}
        >
          {new Date().getFullYear()} Asphalt Calculator - All Rights Reserved
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
