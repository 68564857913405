import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  Paper,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material';
import { Build as BuildIcon, Construction as ConstructionIcon, MonetizationOn as MonetizationOnIcon, Engineering as EngineeringIcon, Info as InfoIcon, CheckCircle as CheckCircleIcon, Calculate as CalculateIcon, MenuBook as MenuBookIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const InfoSection: React.FC = () => {
  return (
    <Box sx={{ py: 8, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        {/* About Section with Features */}
        <Box id="about" sx={{ mb: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ 
            fontWeight: 700,
            textAlign: 'center',
            mb: 3
          }}>
            About Professional Asphalt Pro
          </Typography>
          <Typography variant="body1" paragraph color="text.secondary" sx={{ 
            mb: 6,
            textAlign: 'center',
            maxWidth: '900px',
            mx: 'auto'
          }}>
            Our professional bitumen and tarmac calculator helps contractors, engineers, and DIY enthusiasts accurately estimate materials and costs for paving projects. Whether it's a driveway, parking lot, or road construction, our tool delivers precise calculations based on industry standards.
          </Typography>

          {/* Features Grid */}
          <Grid container spacing={4} sx={{ mb: 8 }}>
            {[
              {
                icon: <BuildIcon sx={{ fontSize: 40 }} />,
                title: 'Precise Calculations',
                description: 'Get accurate measurements for asphalt quantity, area coverage, and material thickness requirements.'
              },
              {
                icon: <MonetizationOnIcon sx={{ fontSize: 40 }} />,
                title: 'Cost Estimation',
                description: 'Calculate project costs based on current market rates and material quantities.'
              },
              {
                icon: <ConstructionIcon sx={{ fontSize: 40 }} />,
                title: 'Multiple Project Types',
                description: 'Support for driveways, parking lots, roads, and repair projects.'
              },
              {
                icon: <EngineeringIcon sx={{ fontSize: 40 }} />,
                title: 'Professional Grade',
                description: 'Used by contractors and engineers for reliable project planning.'
              }
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper elevation={0} sx={{ p: 3, height: '100%', backgroundColor: 'rgba(224, 251, 252, 0.1)' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {feature.icon}
                    <Typography variant="h6" sx={{ my: 2, fontWeight: 600 }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* How to Use Section */}
          <Box sx={{ mb: 8 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 700, mb: 4, textAlign: 'center' }}>
              How to Use the Asphalt Estimator
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary', maxWidth: '800px', mx: 'auto' }}>
              Follow our comprehensive guide to get accurate asphalt calculations for your paving project. Our step-by-step process ensures reliable estimates for any project size.
            </Typography>
            
            <Grid container spacing={4}>
              {/* Step by Step Guide */}
              <Grid item xs={12} md={6}>
                <Card elevation={0} sx={{ 
                  height: '100%', 
                  backgroundColor: 'rgba(224, 251, 252, 0.1)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}>
                  <CardHeader
                    avatar={<BuildIcon color="primary" sx={{ fontSize: 36 }} />}
                    title={
                      <Typography variant="h4" sx={{ 
                        fontWeight: 700,
                        fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                        lineHeight: 1.2
                      }}>
                        Step-by-Step Guide
                      </Typography>
                    }
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <List>
                      {[
                        {
                          text: 'Measure project area accurately (length × width)',
                          subtext: 'Use a measuring tape or laser measure for precision'
                        },
                        {
                          text: 'Determine required thickness',
                          subtext: '2-4 inches for driveways, 4-6 inches for parking lots'
                        },
                        {
                          text: 'Select asphalt type and density',
                          subtext: 'Hot Mix (135-145 lbs/ft³), Cold Mix (130-140 lbs/ft³)'
                        },
                        {
                          text: 'Account for compaction factor',
                          subtext: 'Add 15-20% for proper compaction'
                        },
                        {
                          text: 'Add contingency for waste',
                          subtext: '5-6% extra for cutting and irregularities'
                        }
                      ].map((step, index) => (
                        <ListItem key={index} sx={{ py: 1 }}>
                          <ListItemIcon>
                            <Box sx={{ 
                              width: 24, 
                              height: 24, 
                              borderRadius: '50%', 
                              backgroundColor: 'primary.main',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: 'white',
                              fontSize: '0.875rem',
                              fontWeight: 600
                            }}>
                              {index + 1}
                            </Box>
                          </ListItemIcon>
                          <ListItemText 
                            primary={step.text}
                            secondary={step.subtext}
                            primaryTypographyProps={{
                              sx: { fontWeight: 500 }
                            }}
                            secondaryTypographyProps={{
                              sx: { fontSize: '0.875rem' }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowForwardIcon />}
                        href="/measurement-guide"
                        sx={{
                          mt: 2,
                          textTransform: 'none',
                          borderRadius: 2,
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            transition: 'transform 0.2s'
                          }
                        }}
                      >
                        View Detailed Guide
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Tips and Best Practices */}
              <Grid item xs={12} md={6}>
                <Card elevation={0} sx={{ 
                  height: '100%', 
                  backgroundColor: 'rgba(224, 251, 252, 0.1)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}>
                  <CardHeader
                    avatar={<InfoIcon color="primary" sx={{ fontSize: 36 }} />}
                    title={
                      <Typography variant="h4" sx={{ 
                        fontWeight: 700,
                        fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                        lineHeight: 1.2
                      }}>
                        Tips for Accurate Results
                      </Typography>
                    }
                    sx={{ pb: 0 }}
                  />
                  <CardContent>
                    <List>
                      {[
                        {
                          text: 'Break down irregular areas',
                          subtext: 'Divide complex shapes into rectangles and triangles'
                        },
                        {
                          text: 'Account for slope variations',
                          subtext: 'Measure actual surface area, not just horizontal projection'
                        },
                        {
                          text: 'Consider drainage requirements',
                          subtext: 'Plan for proper water runoff with adequate slope'
                        },
                        {
                          text: 'Verify site conditions',
                          subtext: 'Check soil stability and existing surface condition'
                        },
                        {
                          text: 'Double-check measurements',
                          subtext: 'Verify all dimensions before final calculations'
                        }
                      ].map((tip, index) => (
                        <ListItem key={index} sx={{ py: 1 }}>
                          <ListItemIcon>
                            <CheckCircleIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={tip.text}
                            secondary={tip.subtext}
                            primaryTypographyProps={{
                              sx: { fontWeight: 500 }
                            }}
                            secondaryTypographyProps={{
                              sx: { fontSize: '0.875rem' }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Quick Navigation Cards */}
          <Grid container spacing={4} sx={{ mb: 8 }}>
            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ 
                height: '100%', 
                backgroundColor: 'rgba(224, 251, 252, 0.1)',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)'
                },
                display: 'flex',
                flexDirection: 'column'
              }}>
                <CardHeader
                  avatar={<CalculateIcon color="primary" sx={{ fontSize: 36 }} />}
                  title={
                    <Typography variant="h4" sx={{ 
                      fontWeight: 700,
                      fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                      lineHeight: 1.2
                    }}>
                      Calculator
                    </Typography>
                  }
                />
                <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Get precise asphalt quantity and cost estimates based on your project dimensions, type, and specifications. Our calculator accounts for all essential factors.
                  </Typography>
                  <Box sx={{ mt: 'auto' }}>
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      endIcon={<ArrowForwardIcon />}
                      href="/#calculator"
                      sx={{ 
                        mt: 2,
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'white'
                        }
                      }}
                    >
                      Open Calculator
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ 
                height: '100%', 
                backgroundColor: 'rgba(224, 251, 252, 0.1)',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)'
                },
                display: 'flex',
                flexDirection: 'column'
              }}>
                <CardHeader
                  avatar={<MenuBookIcon color="primary" sx={{ fontSize: 36 }} />}
                  title={
                    <Typography variant="h4" sx={{ 
                      fontWeight: 700,
                      fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                      lineHeight: 1.2
                    }}>
                      Asphalt Types & Density
                    </Typography>
                  }
                />
                <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Explore various asphalt types and their specific applications. Learn about different mix densities, temperature requirements, and how they affect your project. Find the perfect match for your needs with our comprehensive guide.
                  </Typography>
                  <Box sx={{ mt: 'auto' }}>
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      endIcon={<ArrowForwardIcon />}
                      href="/density-guide"
                      sx={{ 
                        mt: 2,
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'white'
                        }
                      }}
                    >
                      View Complete Guide
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default InfoSection;
