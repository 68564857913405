import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme, CircularProgress, Box } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import InfoSection from './components/InfoSection';

// Lazy load components
const Calculator = React.lazy(() => import('./components/Calculator'));
const AsphaltDensityGuide = React.lazy(() => import('./components/AsphaltDensityGuide'));
const AsphaltMeasurementGuide = React.lazy(() => import('./components/AsphaltMeasurementGuide'));
const AsphaltApplicationGuide = React.lazy(() => import('./components/AsphaltApplicationGuide'));
const FAQ = React.lazy(() => import('./components/FAQ'));
const PrivacyPolicy = React.lazy(() => import('./components/legal/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./components/legal/TermsOfService'));
const Disclaimer = React.lazy(() => import('./components/legal/Disclaimer'));
const ContactPage = React.lazy(() => import('./components/ContactPage'));
const EmbeddedCalculator = React.lazy(() => import('./components/EmbeddedCalculator'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#2B3A67',
      light: '#3D5A80',
    },
    secondary: {
      main: '#E0FBFC',
    },
    background: {
      default: '#F7F9FC',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
  },
});

// Loading fallback component
const LoadingFallback = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
    <CircularProgress />
  </Box>
);

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box 
          sx={{ 
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header />
          <Box 
            component="main" 
            sx={{ 
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Suspense fallback={<LoadingFallback />}>
              <Routes>
                <Route path="/" element={<>
                  <Calculator />
                  <InfoSection />
                </>} />
                <Route path="/calculator" element={<>
                  <Calculator />
                  <InfoSection />
                </>} />
                <Route path="/density-guide" element={<AsphaltDensityGuide />} />
                <Route path="/measurement-guide" element={<AsphaltMeasurementGuide />} />
                <Route path="/application-guide" element={<AsphaltApplicationGuide />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/calculator/embed" element={
                  <Box sx={{ bgcolor: 'background.default' }}>
                    <EmbeddedCalculator />
                  </Box>
                } />
              </Routes>
            </Suspense>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
