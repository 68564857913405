import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  Container, 
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { Link } from 'react-router-dom';
import CalculateIcon from '@mui/icons-material/Calculate';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';

const Header: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const menuItems = [
    { text: 'Calculator', href: '/#calculator', icon: <CalculateIcon /> },
    { text: 'About', href: '/#about', icon: <InfoIcon /> },
    { text: 'FAQ', href: '/faq', icon: <HelpIcon /> }
  ];

  return (
    <AppBar 
      position="static" 
      color="primary" 
      elevation={0}
      sx={{ borderRadius: 0 }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ py: 1 }}>
          <Box 
            component={Link} 
            to="/"
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              textDecoration: 'none', 
              color: 'inherit',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            <CalculateIcon sx={{ mr: 2, fontSize: 32 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 700 }}>
              Asphalt Calculator Pro
            </Typography>
          </Box>
          
          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2, ml: 'auto' }}>
            <Button color="inherit" href="/#calculator">Calculator</Button>
            <Button color="inherit" href="/#about">About</Button>
            <Button color="inherit" href="/faq">FAQ</Button>
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            color="inherit"
            aria-label="open mobile menu"
            edge="end"
            onClick={toggleMobileMenu}
            sx={{ display: { xs: 'flex', md: 'none' }, ml: 'auto' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: 240,
            bgcolor: 'background.paper',
            borderRadius: 0
          },
          '& .MuiListItem-root': {
            py: 1.5,
            '&:hover': {
              bgcolor: 'primary.light',
              '& .MuiListItemIcon-root, & .MuiListItemText-root': {
                color: 'white'
              }
            }
          }
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem 
              key={item.text} 
              component="a" 
              href={item.href}
              onClick={toggleMobileMenu}
              disablePadding
            >
              <ListItemIcon sx={{ color: 'primary.main', ml: 2 }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{ 
                  color: 'text.primary',
                  '& .MuiTypography-root': {
                    fontWeight: 500
                  }
                }} 
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Header;
